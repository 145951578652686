import styled from "styled-components";

const ContainerEmpty = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 10px;
`

export { ContainerEmpty };