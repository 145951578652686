import styled from "styled-components";

const FlexDiv = styled.div`
  display: flex;
`;

const ContainerTable = styled.div`
  margin-top: 50px;

  .ant-table-wrapper{
    max-width: 400px !important;
  }
`

export { FlexDiv, ContainerTable };