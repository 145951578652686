import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import SidebarMenu from "../components/organisms/Menu";
import RestrictedRoute from "./RestrictedRoute";

import {
  Profile,
  Ponto,
  StandBy,
  UpdateNews,
  MandatoryHours,
  AverageHours,
  Members,
  Roles,
  Login,
  Tasks,
  Tribes,
  Badges,
  Projects,
  Departaments,
} from "../pages";

import HourConsultation from "../pages/Administration/HourConsultation";

function Menu() {
  return (
    <SidebarMenu>
      <Switch>
        <Route path="/ponto" exact component={Ponto} />
        <Route path="/ponto/profile" component={Profile} />
        <Route path="/ponto/standby" component={StandBy} />
        <RestrictedRoute
          minAccessLevel={1}
          path="/ponto/membros"
          component={Members}
        />
        <RestrictedRoute
          minAccessLevel={1}
          path="/ponto/mediahoras"
          component={AverageHours}
        />
        <RestrictedRoute
          minAccessLevel={1}
          path="/ponto/cargos"
          component={Roles}
        />
        <RestrictedRoute
          minAccessLevel={1}
          path="/ponto/tribos"
          component={Tribes}
        />
        <RestrictedRoute
          minAccessLevel={1}
          path="/ponto/projetos"
          component={Projects}
        />
        <RestrictedRoute
          minAccessLevel={1}
          path="/ponto/acompanhamento"
          component={HourConsultation}
        />
        <RestrictedRoute
          path="/ponto/atualizarnoticias"
          minAccessLevel={1}
          component={UpdateNews}
        />
        <RestrictedRoute
          path="/ponto/tarefas"
          minAccessLevel={1}
          component={Tasks}
        />
        <RestrictedRoute
          minAccessLevel={1}
          path="/ponto/reconhecimentos"
          component={Badges}
        />
        <RestrictedRoute
          path="/ponto/horarioobrigatorio"
          minAccessLevel={1}
          component={MandatoryHours}
        />
        <RestrictedRoute
          minAccessLevel={1}
          path="/ponto/departamentos"
          component={Departaments}
        />
      </Switch>
    </SidebarMenu>
  );
}

const Routes = () => {
  return (
    <BrowserRouter>
      <Route exact path="/">
        {<Redirect to="/ponto" />}
      </Route>
      <Route path="/login" component={Login} />
      <Route path="/ponto" component={Menu} />
    </BrowserRouter>
  );
};

export default Routes;